import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OrganisationUser } from '../models/auth-user';
import { ISignUpForm } from './authentication.service';
import { IAPIResponse } from './dashboard.service';
import { TimeZoneService } from './time-zone.service';

export interface ILoginCredentials {
  email: string;
  password: string;
}

export interface IAuthResponse {
  statusCode:number;
  message: string;
  data?: any;
}


export class LoginResponseData {
  accessToken: string;
  refreshToken: string;
  userDetails: OrganisationUser;
}

export class LoginResponse {
  statusCode: number;
  message:string;
  data: LoginResponseData;
}

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  private url = `${environment.apiEndpoint}/api/v1/organisation-management/organisations`;
  constructor(
    private httpService: HttpClient,
    private router: Router,
    private timeZoneService: TimeZoneService
  ) { }

    public getCurrenUser(): OrganisationUser | null {
      const userString = this.getLoggedInUser();
      if (userString == null) {
        return null;
      }
      try {
        const temp = JSON.parse(userString);
        const loggedInUser = new OrganisationUser();
        loggedInUser.name = temp.name;
        loggedInUser.setupCompleted = temp.setupCompleted;
        loggedInUser.email = temp.email;
        loggedInUser.id = temp.id;
        loggedInUser.roleId = temp.roleId;
        loggedInUser.organisationCode = temp.organisationCode;
        loggedInUser.customTaskName = temp.customTaskName
        loggedInUser.subscriptionPlanId = temp.subscriptionPlanId
        loggedInUser.upgradePlanWarning = temp.upgradePlanWarning
        loggedInUser.trialEndDate = temp.trialEndDate
        loggedInUser.isInvoicePending = temp.isInvoicePending
        loggedInUser.employeeId = temp.employeeId
        return loggedInUser;
      } catch (e) {
        console.error(e); 
        throw e;
      }
    }
    public getLoggedInUser(): string {
      const userDetails = sessionStorage.getItem('FocusRoAuthUserDetailsProvider');
      if (userDetails) {
        return userDetails;
      }
      return localStorage.getItem('FocusRoAuthUserDetailsProvider');
    }
    public storeLoggedInUser(data: LoginResponseData, rememberSession: boolean) {
      if (rememberSession) {
        localStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(data.userDetails));
        localStorage.setItem('FocusRoAuthUserToken', data.accessToken);
      } else {
        sessionStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(data.userDetails));
        sessionStorage.setItem('FocusRoAuthUserToken', data.accessToken);
      }
    }
    private authenticate(credentials: ILoginCredentials) {
      const reqUrl = `${this.url}/login`;
      return this.httpService.post<LoginResponse>(reqUrl, credentials);
    }
  /**
   * Signs in an user in to the app using the aws cognito service.
   * @param userEmail Email address the user uses to sign in
   * @param password Password the user uses to sign in
   */
   public login(credentials: ILoginCredentials, rememberSession: boolean) {
    return this.authenticate(credentials).pipe(
      map((response) => {
        
        this.storeLoggedInUser(response.data, rememberSession);
        this.timeZoneService.setTimeZone(response.data.userDetails.orgTimezone);
        const intervel=this.getRefreshTokenIntervel();
        setTimeout(()=>{
          this.refreshToken().subscribe();
        },intervel);
      })
    );
  }
  public getAuthToken(): string | null {
    const token = sessionStorage.getItem('FocusRoAuthUserToken');
    if (token) {
      return token;
    }
    return localStorage.getItem('FocusRoAuthUserToken');
  }
  public isLoggedIn() {
    if (this.getLoggedInUser()) {
      return true;
    }
    return false;
  }
  public logOut(userId: number,noRedirect?: boolean): void;
  public logOut(userId,noRedirect = false): void {
    const reqUrl = `${this.url}/logout`;
    const reqBody = { 
                      userId : userId                     
                    };
    this.httpService.post<IAuthResponse>(reqUrl,reqBody)
      .subscribe((response) => {
        localStorage.removeItem('FocusRoAuthUserDetailsProvider');
        localStorage.removeItem('FocusRoAuthUserToken');
        sessionStorage.removeItem('FocusRoAuthUserDetailsProvider');
        sessionStorage.removeItem('FocusRoAuthUserToken');
        if (!noRedirect) {
          this.router.navigateByUrl('/auth/login');

        }
        this.clearCacheAndRedirect()
      },(error) => {
        console.log(error.error)
      });
  }
  private clearCacheAndRedirect(): void {
    window.location.reload();
  }
  public initPasswordReset(userEmail: string) {
    return new Observable<IAuthResponse>((observer) => {
      const reqUrl = `${this.url}/forget_password`;
      this.httpService.post<IAuthResponse>(reqUrl, {email:userEmail.trim()})
        .subscribe((response) => {
          observer.next(response);
        }, (error) => {
          observer.error({
            message: 'unable to send password reset code',
            error: error.error
          });
        });
    });
  }

  public changePassword(resetCode: number, newPassword: string, email: string) {
    return new Observable<IAuthResponse>((observer) => {
      const reqUrl = `${this.url}/reset_password`;
      const reqBody = { 
                        password_reset_code : resetCode,
                        new_password : newPassword.trim(),
                        email : email.trim()
                      };
      this.httpService.post<IAuthResponse>(reqUrl,reqBody)
      .subscribe((response) => {
        observer.next(response);
      }, (error) => {
        observer.error({
          message: 'unable to change the password',
          error: error.error
        });
      });
    });
  }
  public signUp(request: ISignUpForm) {
    return new Observable((observer) => {
      const reqUrl = `${this.url}/register`;
      const reqBody = {
                        name : request.name,
                        organisationCode: request.nickName,
                        email : request.email,
                        password : request.password
                      };
      this.httpService.post<IAuthResponse>(reqUrl,reqBody)
      .subscribe((response) => {
        observer.next(response);
        },
          (error) => {
            observer.error({
              message: 'sign up failed',
              error: error.error
            });
          });
    });
  }
  public verifySignUp(userEmail: string, token: string) {
    return new Observable<IAuthResponse>((observer) => {
      const reqUrl = `${this.url}/verify_email`;
      const reqBody = { 
                        email : userEmail.trim(),
                        verification_code : token                        
                      };
      this.httpService.post<IAuthResponse>(reqUrl,reqBody)
        .subscribe((response) => {
          observer.next(response);
        },(error) => {
          observer.error({
            message: 'Verification Failed',
            error: error.error
          });
        });
       
    });

  }

  public resendVerifcationCode(userEmail: string) {
    return new Observable<IAuthResponse>((observer) => {
      const reqUrl = `${this.url}/resend_code`;
      const reqBody = { 
                        email : userEmail.trim()                    
                      };
      this.httpService.post<IAuthResponse>(reqUrl,reqBody)
        .subscribe((response) => {
          observer.next(response);
        },(error) => {
          observer.error({
            message: 'Unable to Resend Verification Code',
            error: error.error
          });
        })
    });
  }
  public refreshToken()
  {
    return  new Observable((observer) => {
    const reqUrl = `${this.url}/refreshToken`;
    this.httpService.post<IAuthResponse>(reqUrl, {})
        .subscribe((response) => {
            const accessToken= response.data.accessToken;
            if(localStorage.getItem("FocusRoAuthUserToken"))
            {
              localStorage.setItem('FocusRoAuthUserToken', accessToken);
            }
            else if(sessionStorage.getItem("FocusRoAuthUserToken"))
            {
              sessionStorage.setItem('FocusRoAuthUserToken',accessToken);
            }
            const intervel=this.getRefreshTokenIntervel();
            setTimeout(()=>{
              this.refreshToken().subscribe();
            },intervel);

            observer.next(accessToken);
        }, (error) => {
            console.log(error.error);
            observer.error(error.error.message);
            const user= this.getCurrenUser();
            this.logOut(user.id);
        });
      }
    );
  }
  public checkTokenExpired(token: string)
  {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
  // public getTokenExpireTime(token: string)
  // {
  //   console.log('token:', token)
  //   console.log('token first split:', token.split('.'))
  //   console.log('token:', token.split('.')[1])
  //   console.log('token split atob:', (atob(token.split('.')[1])))
  //   console.log('JSON Parsed Token expire Time :', (JSON.parse(atob(token.split('.')[1]))).exp)
  //   const expire_time = (JSON.parse(atob(token.split('.')[1]))).exp;
  //   // const expire_time = (JSON.parse(window.atob(token.split('.')[1]))).exp;
  //   // const expire_time = (JSON.parse(window.atob(token.replace(/-/g, "+").replace(/_/g, "/")))).exp;
  //   console.log('exp', expire_time)
  //   return expire_time;
  // }
  public getTokenExpireTime(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/').padEnd(base64Url.length + (4 - base64Url.length % 4) % 4, '=');
      const expire_time = (JSON.parse(atob(base64))).exp;
      return expire_time;
    } catch (error) {
      console.error("Error decoding token: ", error);
      // Handle the error accordingly
      return null;
    }
  }
  public getRefreshTokenIntervel()
  {
    const currentTime = Date.now();
    const token = this.getAuthToken();  
    const expireTime = this.getTokenExpireTime(token)*1000;
    const time = expireTime - currentTime -(60*1000);//refresh token one minut before expire;
    return time;
  }

  public verifyLoginToken(orgCode: string, token: string) {
    return new Observable<IAuthResponse>((observer) => {
      const reqUrl = `${this.url}/${orgCode}/verify-login-token`;
      const reqBody = { 
                        loginToken : token                        
                      };
      this.httpService.post<IAuthResponse>(reqUrl,reqBody)
        .subscribe((response) => {
          this.timeZoneService.setTimeZone(response.data.userDetails.orgTimezone);
          observer.next(response);
        },(error) => {
          observer.error({
            message: 'Verification Failed',
            error: error.error
          });
        });
       
    });
  }

  public getPabblyLoginUrl(orgCode: string) {
    return this.httpService.get<IAPIResponse<IPabblyLoginResp>>(`${this.url}/${orgCode}/pabbly-login`);
  }

  public getStripeLoginUrl(orgCode: string) {
    return this.httpService.get<IAPIResponse<IStripeLoginResp>>(`${this.url}/${orgCode}/invoice-payement-link`);
  }

  public getStripeCustomerPortal(orgCode: string) {
    return this.httpService.get<IAPIResponse<IStripeLoginResp>>(`${this.url}/${orgCode}/stripe-customer-portal`);
  }

  public getStripeDetails(orgCode: string) {
    return this.httpService.get<IAPIResponse<IOrganisationDetailsResp>>(`${this.url}/${orgCode}/get-stripe-details`);
  }


  
}

export interface IPabblyLoginResp{
  token: string;
  access_url: string;
  expires_at: Date;
}

export interface IStripeLoginResp{  
  access_url: string;  
}

export interface IOrganisationDetailsResp{
  stripeDetails: any;  
}