import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { UserAuthService } from '../services/user-auth.service';
import { concatMap, catchError } from 'rxjs/operators';
import { CurrentUser,OrganisationUser } from '../models/auth-user';
import { LoggedInUser, ManagerialOperationsService } from '../services/managerial-operations.service';

export enum HTTPMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

type httpMethod = keyof typeof HTTPMethods;

interface IgnoredUrl {
    url: string;
    method: httpMethod;
}

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

    private ignoreList: IgnoredUrl[] = [
        {
            url: '/api/v1/managerial-operations/auth',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/login',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/:email',
            method: HTTPMethods.GET
        },
        {
            url: '/api/v1/organisation-management/organisations/register',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/check-availability',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/employee-management/:organisationCode/employees/:verificationToken/change-password',
            method: HTTPMethods.PUT
        },
        {
            url: '/api/v1/organisation-management/organisations/register',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/verify_email',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/resend_code',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/forget_password',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/reset_password',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/organisation-management/organisations/:organisationCode/verify-login-token',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/managerial-operations/:organisationCode/reset-password',
            method: HTTPMethods.POST
        },
        {
            url: '/api/v1/managerial-operations/:organisationCode/change-password',
            method: HTTPMethods.PUT
        },
        {
            url : '/api/v1/organisation-management/organisations/employee-admin-login',
            method: HTTPMethods.POST
        }
    ];
    constructor(
        private authService: AuthenticationService,
        private managerService: ManagerialOperationsService,
        private uauthService : UserAuthService
    ) { }

    private matchUrl(url: string, method: string) {

        // extract the api part of the url
        const startIndex = url.indexOf('/api/');
        const endIndex = url.indexOf('?');
        const apiUrl = endIndex >= 0 ? url.substring(startIndex, endIndex) : url.substring(startIndex);

        // sort list based on length for better matching
        const sortedList = this.ignoreList.sort((a, b) => {
            return b.url.length - a.url.length;
        });

        // replace the route params with Regex to match the route params
        // and convert the urls in the ignorelist into a RegEx list
        const routes = sortedList.map((urlItem) => {
            return {
                route: new RegExp('^' + urlItem.url.replace(/:[^\s/]+/g, '([\\w-@.+]+)') + '$'),
                method: urlItem.method
            };
        });

        // check for matches in the ignore list
        for (const item of routes) {
            const matched = apiUrl.match(item.route);
            if (matched && item.method === method) {
                return true;
            }
        }
        return false;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log(`Request URL: ${req.url}`);

        // Check if url is in the ignore list
        if (this.matchUrl(req.url, req.method)) {
            // console.log(req.url + ' ignored');
            return next.handle(req);
        }
        let roleId = 0;
        return this.authService.getCurretUser().pipe(
            catchError((err) => {
                // console.error(err);
                throw err;
            }),
            concatMap(
                (userInfo) => {
                    if (userInfo instanceof CurrentUser) {
                        roleId = userInfo.UserDetails.roleId;
                        return this.uauthService.getAuthToken();
                    } else if (userInfo instanceof LoggedInUser) {
                        roleId = userInfo.roleId;
                        return of(this.managerService.getAuthToken());
                    } else if (userInfo instanceof OrganisationUser)
                    {
                        roleId = userInfo.roleId;
                        return of(this.uauthService.getAuthToken());
                    }
                }),
            concatMap(
                (idToken) => {

                    // Attach bearer token and role to appropriate headers
                    const request = req.clone({
                        headers: new HttpHeaders({
                            Authorization: `Bearer ${idToken}`,
                            'focusro-access-role': roleId.toString()
                        })
                    });
                    // console.log('Intercepted');
                    return next.handle(request);
                }
            )
        );
    }
}
